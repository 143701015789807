// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.about-heading {
  font-size: 2rem;
  margin-bottom: 20px;
}

.about-content {
  font-size: 1.1rem;
  line-height: 1.6;
}

.about-content p {
  margin-bottom: 20px;
}
.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.about-content {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
}

.about-content p {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/AboutPage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".about-container {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.about-heading {\n  font-size: 2rem;\n  margin-bottom: 20px;\n}\n\n.about-content {\n  font-size: 1.1rem;\n  line-height: 1.6;\n}\n\n.about-content p {\n  margin-bottom: 20px;\n}\n.about-container {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 20px;\n  background-color: #f4f4f4;\n  border-radius: 10px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.about-heading {\n  font-size: 2.5rem;\n  margin-bottom: 20px;\n  color: #333;\n}\n\n.about-content {\n  font-size: 1.2rem;\n  line-height: 1.8;\n  color: #555;\n}\n\n.about-content p {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
