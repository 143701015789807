// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden {
  opacity: 0;
  transform: translateY(
    20px
  ); /* Move the section slightly down to give a smooth appearance */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Apply transition effect for smooth appearance */
}

.visible {
  opacity: 1;
  transform: translateY(0);
}
`, "",{"version":3,"sources":["webpack://./src/HomePage.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV;;GAEC,EAAE,+DAA+D;EAClE,kDAAkD,EAAE,kDAAkD;AACxG;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B","sourcesContent":[".hidden {\n  opacity: 0;\n  transform: translateY(\n    20px\n  ); /* Move the section slightly down to give a smooth appearance */\n  transition: opacity 0.5s ease, transform 0.5s ease; /* Apply transition effect for smooth appearance */\n}\n\n.visible {\n  opacity: 1;\n  transform: translateY(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
