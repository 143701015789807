// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SelectTimeTable.css */

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center the content horizontally */
}

h2 {
  font-size: 32px; /* Increase the font size */
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase; /* Convert text to uppercase */
  letter-spacing: 2px; /* Add letter spacing for a stylish look */
  border-bottom: 2px solid #333; /* Add a border under the heading */
  padding-bottom: 10px; /* Add some space below the heading */
}

.link-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the content horizontally */
  gap: 20px;
}

.timetable-link {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 10px;
  border: 2px solid #333;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.timetable-link:hover {
  background-color: #333;
  color: #fff;
}

.para {
  margin: 5px 0;
  color: #666;
  font-style: italic;
  flex-basis: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/SelectTimeTable.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,uCAAuC;EACvC,kBAAkB,EAAE,oCAAoC;AAC1D;;AAEA;EACE,eAAe,EAAE,2BAA2B;EAC5C,mBAAmB;EACnB,WAAW;EACX,yBAAyB,EAAE,8BAA8B;EACzD,mBAAmB,EAAE,0CAA0C;EAC/D,6BAA6B,EAAE,mCAAmC;EAClE,oBAAoB,EAAE,qCAAqC;AAC7D;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB,EAAE,oCAAoC;EAC7D,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":["/* SelectTimeTable.css */\n\n.container {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 20px;\n  background-color: #f8f9fa;\n  border-radius: 10px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  text-align: center; /* Center the content horizontally */\n}\n\nh2 {\n  font-size: 32px; /* Increase the font size */\n  margin-bottom: 20px;\n  color: #333;\n  text-transform: uppercase; /* Convert text to uppercase */\n  letter-spacing: 2px; /* Add letter spacing for a stylish look */\n  border-bottom: 2px solid #333; /* Add a border under the heading */\n  padding-bottom: 10px; /* Add some space below the heading */\n}\n\n.link-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center; /* Center the content horizontally */\n  gap: 20px;\n}\n\n.timetable-link {\n  text-decoration: none;\n  color: #333;\n  font-weight: bold;\n  padding: 10px;\n  border: 2px solid #333;\n  border-radius: 5px;\n  transition: all 0.3s ease;\n}\n\n.timetable-link:hover {\n  background-color: #333;\n  color: #fff;\n}\n\n.para {\n  margin: 5px 0;\n  color: #666;\n  font-style: italic;\n  flex-basis: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
