// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.logo {
  margin: 0;
}
.logo {
  width: 40px; /* Set the width of the image */
  height: 40px; /* Set the height of the image */
  border-radius: 50%; /* Make the image circular */
  background-color: #ccc; /* Set a background color */
  object-fit: cover; /* Ensure the image covers the container */
}

.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.nav-links a:hover,
.nav-links a.active {
  color: #ff9900; /* Change color on hover/active */
}

.dropdown {
  display: none;
  position: absolute;
  background-color: #333;
  z-index: 1;
}

.dropdown li {
  padding: 10px;
}

.dropdown li:hover {
  background-color: #555;
}

.dropdown li a {
  color: #fff;
}

.nav-links li:hover .dropdown {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/Header.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,SAAS;AACX;AACA;EACE,WAAW,EAAE,+BAA+B;EAC5C,YAAY,EAAE,gCAAgC;EAC9C,kBAAkB,EAAE,4BAA4B;EAChD,sBAAsB,EAAE,2BAA2B;EACnD,iBAAiB,EAAE,0CAA0C;AAC/D;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,UAAU;EACV,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;;EAEE,cAAc,EAAE,iCAAiC;AACnD;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".header {\n  background-color: #333;\n  color: #fff;\n  padding: 20px;\n}\n\n.logo {\n  margin: 0;\n}\n.logo {\n  width: 40px; /* Set the width of the image */\n  height: 40px; /* Set the height of the image */\n  border-radius: 50%; /* Make the image circular */\n  background-color: #ccc; /* Set a background color */\n  object-fit: cover; /* Ensure the image covers the container */\n}\n\n.nav-links {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  display: flex;\n}\n\n.nav-links li {\n  margin-right: 20px;\n}\n\n.nav-links li:last-child {\n  margin-right: 0;\n}\n\n.nav-links a {\n  color: #fff;\n  text-decoration: none;\n  font-weight: bold;\n}\n\n.nav-links a:hover,\n.nav-links a.active {\n  color: #ff9900; /* Change color on hover/active */\n}\n\n.dropdown {\n  display: none;\n  position: absolute;\n  background-color: #333;\n  z-index: 1;\n}\n\n.dropdown li {\n  padding: 10px;\n}\n\n.dropdown li:hover {\n  background-color: #555;\n}\n\n.dropdown li a {\n  color: #fff;\n}\n\n.nav-links li:hover .dropdown {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
