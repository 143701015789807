// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.not-found-heading {
  font-size: 2.5rem;
  color: #333;
}

.not-found-message {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

.not-found-suggestion a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.not-found-suggestion a:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/PageNotFound.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".not-found-container {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px;\n  text-align: center;\n}\n\n.not-found-heading {\n  font-size: 2.5rem;\n  color: #333;\n}\n\n.not-found-message {\n  font-size: 1.2rem;\n  color: #555;\n  margin-bottom: 20px;\n}\n\n.not-found-suggestion a {\n  color: #007bff;\n  text-decoration: none;\n  font-weight: bold;\n}\n\n.not-found-suggestion a:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
